<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <b-card id="information" :title="$t('Basic Information')">
            <b-row>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Title') + `:`" label-cols-md="12">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.title"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Title')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                  <b-form-input
                    v-model="model.title"
                    :placeholder="$t('Title')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Link') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Link"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.link"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Link"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group :label="`*` + $t('Content') + `:`" label-cols-md="12">
              <!-- <validation-provider
                #default="{ errors }"
                name="Content"
                rules="required"
              >
                <b-form-textarea
                  id="Content"
                  v-model="model.content"
                  rows="2"
                  trim
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> -->
              <b-form-textarea
                id="Content"
                v-model="model.content"
                rows="2"
                trim
              />
            </b-form-group>
            <b-row class="mt-2">
              <b-col cols="6">
                <b-form-group :label="`*`+$t('Position')+`:`" label-cols-md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="Position"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.position"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Position')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('Display on/off')+`:`" label-cols-md="3">
                  <b-form-checkbox
                    v-model="model.is_active"
                    checked="true"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <b-card id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.avatar"
                @previewFiles="previewFiles"
              />
            </b-card>
            <div class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                {{ $t('Create new') }}
              </b-button>
            </div>
            <!-- <b-card id="link" title="Link">
              <validation-provider
                #default="{ errors }"
                name="Link"
                rules="required"
              >
                <b-form-input
                  v-model="model.link"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-card> -->
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BCard,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormCheckbox,
    BCard,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      model: {
        avatar: this.avatarDefault(),
        title: null,
        position: null,
        is_active: true,
        content: null,
        link: null,
      },
    }
  },
  methods: {
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              title: this.model.title,
              content: this.model.content,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              position: Number(this.model.position),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              link: this.model.link,
              title: this.model.title,
              content: this.model.content,
            }
            if (this.model.avatar !== null) {
              params.avatar = this.model.avatar
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/slider`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Slider success',
                  },
                })
                this.$router.push('/slider/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
