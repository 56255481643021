import { render, staticRenderFns } from "./SliderAdd.vue?vue&type=template&id=89084058&scoped=true&"
import script from "./SliderAdd.vue?vue&type=script&lang=js&"
export * from "./SliderAdd.vue?vue&type=script&lang=js&"
import style0 from "./SliderAdd.vue?vue&type=style&index=0&id=89084058&prod&lang=scss&scoped=true&"
import style1 from "./SliderAdd.vue?vue&type=style&index=1&id=89084058&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89084058",
  null
  
)

export default component.exports